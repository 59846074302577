<template>
  <section class="section-2">
    <div class="content">
      <div class="title">产品介绍</div>
      <div class="line">
        <div class="line-color" />
      </div>
      <div class="img-row-1">
        <img src="../../../assets/images/home/introduce-row-1.png" alt="" />
      </div>
      <div class="img-row-2">
        <div class="item">
          <img src="../../../assets/images/home/introduce-row-2-1.png" alt="" />
          <p>智能出纸机</p>
        </div>
        <div class="item">
          <img src="../../../assets/images/home/introduce-row-2-2.png" alt="" />
          <p>小程序</p>
        </div>
        <div class="item">
          <img src="../../../assets/images/home/introduce-row-2-3.png" alt="" />
          <p>运维端</p>
        </div>
      </div>
      <div class="text-row-1">
        <div>
          <div class="text">
            <p>智能出纸机是刘小白旗下智慧厕所生态链核心产品，</p>
            <p>
              是由浙江刘小白智能科技有限公司独立研发、拥有自主知识产权的终端设备。
            </p>
            <p>
              通过智能出纸机物联网属性，构建智慧厕所，轻松实现厕位设施互联的应用体验，提高运维效率，为厕所革命添砖加瓦。
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Slide1",
  components: {},
};
</script>

<style scoped lang="less">
.section-2 {
  position: relative;
  width: 100vw;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 670px;
    display: flex;
    flex-direction: column;

    > .title {
      width: 284px;
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      display: flex;
      justify-content: center;
    }

    .line {
      margin-top: 40px;
      width: 1200px;
      height: 4px;
      background: #f2f4f8;
      position: relative;

      .line-color {
        width: 284px;
        height: 4px;
        background: #fe690c;
      }
    }
    .img-row-1 {
      position: absolute;
      top: 112px;
      right: 0;
      img {
        width: 509px;
        height: 491px;
      }
    }
    .img-row-2 {
      width: 952px;
      height: 305px;
      background: #ffffff;
      box-shadow: 0 5px 12px 0 rgba(185, 185, 185, 0.5);
      border-radius: 2px;
      padding: 42px 48px;
      box-sizing: border-box;
      position: absolute;
      top: 368px;
      left: 0;
      display: flex;
      justify-content: space-between;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        img {
          width: 264px;
          height: 221px;
        }
        p {
          position: absolute;
          width: 264px;
          height: 68px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.6) 100%
          );
          font-size: 18px;
          color: #ffffff;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .text-row-1 {
      position: absolute;
      top: 160px;
      left: 0;
      .text {
        margin-top: 20px;
        width: 585px;
        height: 154px;
        font-size: 14px;
        color: #555555;
        line-height: 25px;
      }
    }
  }
}
</style>
